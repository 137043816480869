import React, { useState } from 'react';
import ImageGallery from '../ImageGallery/ImageGallery';
import VideoGallery from '../VideoGallery/VideoGallery';
import SocialMedia from '../SocialMedia/SocialMedia';
import style from './StudentGallery.module.css';

export default function StudentGallery() {
  const [activeCategory, setActiveCategory] = useState('graphic'); // التحكم بالفئة

  return (
    <div className={style.galleryContainer}>
      <h1 className={`mb-4 ${style.txt}`}>معرض أعمال الطلاب</h1>
      
      <div className={`${style.categoryContainer} row`}>
        <button
          onClick={() => setActiveCategory('graphic')}
          className={`col-12 mb-1 ${style.toggleButton} ${activeCategory === 'graphic' ? style.active : ''}`}
        >
           أعمال طلاب في تصميم السوشيال ميديا

        </button>
        <button
          onClick={() => setActiveCategory('social')}
          className={` col-12 mb-1 ${style.toggleButton} ${activeCategory === 'social' ? style.active : ''}`}
        >
          أعمال طلاب في تصميم الجرافيكي
        </button>
        <button
          onClick={() => setActiveCategory('videos')}
          className={`col-12 mb-1 ${style.toggleButton} ${activeCategory === 'videos' ? style.active : ''}`}
        >
          اعمال طلاب في الموشن جرافيك
        </button>
      </div>

      {activeCategory === 'graphic' && <ImageGallery />}
      {activeCategory === 'social' && <SocialMedia/>}
      {activeCategory === 'videos' && <VideoGallery />}
    </div>
  );
}
