import React, { useState, useEffect, useContext } from 'react';
import ReactPlayer from 'react-player';
import style from './Courses.module.css';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Certificate from '../../Assets/images/شهادة-اتمام-الدورة.png';
import Levels from '../../Assets/images/ثلاث-مستويات.png';
import Students from '../../Assets/images/عدد-طلاب.png';
import Clock from '../../Assets/images/ساعة.png';
import Lectuers from '../../Assets/images/عدد-المحاضرات.png';
import Lock from '../../Assets/images/قفل.png';
import Select from 'react-select';
import { toast, Toaster } from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';

const Courses = () => {
  const [TransferNumber, setTransferNumber] = useState('')
  const [paymentChoice, setPaymentChoice] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [videoOptions, setVideoOptions] = useState([]);
  const [SectionSlugs, setSectionSlugs] = useState('');
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentVideo, setCurrentVideo] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [expandedSection, setExpandedSection] = useState(null);
  const [courseSlug, setCourseSlug] = useState('');
  const [sectionSlug, setSectionSlug] = useState('');
  const [selectedLectureSlug, setSelectedLectureSlug] = useState( '');
  const [paymentMethod, setPaymentMethod] = useState(null); // لتخزين طريقة الدفع
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false); // لتأكيد الاختيار
  const [showModal, setShowModal] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const { baseUrl } = useContext(BaseUrlContext);
  const { slug } = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [errorrMessage, setErrorrMessage] = useState('');
  const [sectionDatum, setSectionDatum] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [cachedSections, setCachedSections] = useState({});
  const [step, setStep] = useState(0); 
  const [phone, setPhone] = useState(''); 
  const [image, setImage] = useState(null); 
  const [authenticated, setauthenticated] = useState(localStorage.getItem('token'))

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };
  
  const handleNext = () => {
    if (!authenticated) {
      navigate('/login'); 
      toast.error('عليك بتسجيل الدخول حتى يمكنك اجراء عملية شراء')
      return;
    }
  
    if (paymentMethod) {
      setIsPaymentConfirmed(true);
      setStep(1);
    }
  };
  
 
  
  const handleprev1 = () => {
    setStep(1); 
  };
  

 
  const handleSendPaymentDetails = async () => {
    if (!phone || !paymentMethod || !paymentChoice || !termsAccepted) {
      toast.error('يرجى التأكد من إدخال جميع البيانات المطلوبة.');
      return;
    }
  
    const formData = new FormData();
    formData.append('course_installment_id', data.data.data.installments[0].id);
    formData.append('whatsapp_number', phone);
    formData.append('payment_type', paymentMethod);
    formData.append('payment_method', paymentChoice);
  
    if (image) {
      formData.append('transfer_image', image);
    }
    formData.append('transfer_number', TransferNumber);
  
    console.log('Data to be sent:', {
      course_slug: slug,
      whatsapp_number: phone,
      payment_type: paymentMethod,
      payment_method: paymentChoice,
      transfer_image: image,
      transfer_number: TransferNumber,
    });
  
    try {
      setIsLoadingg(true); 
      const token = localStorage.getItem('token');
      const response = await axios.post(`${baseUrl}api/payment-details`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('تم إرسال البيانات بنجاح:', response.data);
      toast.success('تم إرسال البيانات بنجاح');
      setShowModal(false); 
      if (window.fbq) {
        window.fbq('track', 'Purchase');
      }
    } catch (error) {
      console.error('حدث خطأ أثناء إرسال البيانات:', error);
      toast.error('حدث خطأ أثناء إرسال البيانات، يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoadingg(false); // إيقاف التحميل
    }
  };
  
  
  
  const handleNext1 = () => {
    if (paymentChoice === 'instapay') {
      window.open('https://ipn.eg/S/abdallahgamal2003/instapay/1cBPMJ', '_blank');
      setStep(2); 
    }
     else {
      console.log('محفظة تم اختيارها');
      setStep(2); 
    }
  };

  const handleFileChange = (event) => {
    setImage(event.target.files[0]); 
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const getCourseData = () => {
    return axios.get(`${baseUrl}api/course/${slug}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

  const getSectionData = async () => {
    setLoading(true);
    setSectionDatum(null); 
    try {
      const response = await axios.get(`${baseUrl}api/course/${slug}/section/${SectionSlugs}/lectures`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSectionDatum(response.data);
    } catch (error) {
      console.error('Error fetching section data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  const getLectureData = (sectionSlug, lectureSlug) => {
    console.log('from server');
    return axios.get(`${baseUrl}api/course/${slug}/section/${sectionSlug}/lecture/${lectureSlug}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };
  
  const { isLoading, isFetching, data } = useQuery('getCourseData', getCourseData, {
    refetchOnWindowFocus: false,
  });
  
  const { data: lectureData, isLoading: isLectureLoading } = useQuery(
    ['getLectureData', sectionSlug, selectedLectureSlug],
    () => getLectureData(sectionSlug, selectedLectureSlug),
    {
      enabled: !!sectionSlug && !!selectedLectureSlug,
    }
  );
  // useEffect(() => {
  //   const cachedData = localStorage.getItem('cachedSections');
  //   if (cachedData) {
  //     console.log("Cached Sections:", JSON.parse(cachedData));
  //   }
  // }, []);





  useEffect(() => {
    if (data && data.data && data.data.data) {
      const courseData = data.data.data;
      setCourseId(courseData.slug);

      if (courseData.slug && courseData.sections && courseData.sections.length > 0) {
        const courseSlug = courseData.slug;

        setCourseSlug(courseSlug);
      }
      
    }
   

  }, [data]);

  useEffect(() => {
    // Fetch section data whenever SectionSlugs changes
    if (SectionSlugs) {
      getSectionData();
    }
  }, [SectionSlugs]);

  useEffect(() => {
    if (selectedLectureSlug && lectureData?.data) {
      const video = lectureData.data.data.video || '';  
      const attachments = lectureData.data.attachments || [];
    
      setCurrentVideo(video); 
      setAttachments(attachments);
  
    }
  }, [selectedLectureSlug, lectureData]);
  
  
  
  

  const handleSectionClick = (newSectionSlug) => {
    setSectionSlugs(newSectionSlug);
    setSectionSlug(newSectionSlug);
    setExpandedSection(null);
  };

  // const findSupportedVideo = (videos) => {
  //   if (videos && videos.video) {
  //     const youtubeUrl = videos.video;
  //     setCurrentVideo(youtubeUrl); 
  //     setVideoOptions([]); 
  
  //     // تخزين الرابط في localStorage
  //     localStorage.setItem('currentVideo', youtubeUrl);
  //     localStorage.setItem('videoOptions', JSON.stringify([]));
  //   } else {
  //     setCurrentVideo(null); // إذا لم يوجد فيديو
  //     setVideoOptions([]); // إزالة الخيارات
  //   }
  // };
  
  const handleVideoClick = (index, newSectionSlug, newLectureSlug, title) => {
    setActiveIndex(index);
    setSectionSlug(newSectionSlug);
    setSelectedLectureSlug(newLectureSlug);
    setVideoTitle(title);  // تعيين عنوان الفيديو
    
   
  };
  
  
  // const handleVideoQualityChange = (selectedOption) => {
  //   setCurrentVideo(selectedOption.value);
  //   localStorage.setItem('currentVideo', selectedOption.value);
  // };

  const handleModalToggle = () => {
    setShowModal(!showModal);
    setIsPaymentConfirmed(false); 
    setPaymentMethod(null); 
    setStep(0);
  };

  const trackInitiateCheckout = () => {
    if (window.fbq) {
      window.fbq('track', 'InitiateCheckout');
    }
  };

  const handleAttachmentsModalToggle = () => {
    setShowAttachmentsModal(!showAttachmentsModal);
  };

  function convertToArabicNumbers(text) {
    const arabicNumerals = '٠١٢٣٤٥٦٧٨٩';
    return text?.replace(/\d/g, (match) => arabicNumerals[parseInt(match)]);
  }

  return (
    <>
      {errorMessage && (
        <div style={{
          position: 'absolute',
          top: '20px', 
          left: '50%',
          transform: 'translateX(-50%)', 
          backgroundColor: 'white',
          color: '#721c24',
          padding: '10px 20px', 
          border: '1px solid #f5c6cb',
          borderRadius: '5px',
          zIndex: '1000', 
        }}>
          {errorMessage}
        </div>
      )}

      <div className={`${style.course} p-2 p-sm-5`}>
        {/* Header Section */}
        <div className={`${style.header} my-3 my-sm-5 d-lg-flex d-md-block justify-content-between align-items-center`}>
          <div className={`${style.left}`}>
            <h1>{data?.data?.data?.title}</h1>
            <div className={`${style.specifications1} mt-4 d-flex justify-content-start align-items-center gap-2`}>
              <p>تقيمات ({convertToArabicNumbers(data?.data?.data?.rating.toString())}) </p>
              <img className={`${style.ig}`} src={Certificate} alt="شهادة اتمام الدورة" />
              <img className={`${style.ig}`} src={Levels} alt="ثلاث مستويات" />
            </div>
            <div className={`${style.specifications2} mb-3 mt-3 mt-sm-4 mb-sm-0 d-flex justify-content-start align-items-center gap-2`}>
              <div className="d-flex align-items-center gap-1">
                <img src={Students} alt="عدد طلاب" />
                <p>{convertToArabicNumbers(data?.data?.data?.students_count.toString())}</p>
              </div>
              <div className={`${style.middle} d-flex align-items-center gap-1 px-3 mx-1`}>
                <img src={Clock} alt="ساعة" />
                <p>{convertToArabicNumbers(data?.data?.data?.total_duration.toString())} ساعة</p>
              </div>
              <div className="d-flex align-items-center gap-1">
                <img src={Lectuers} alt="عدد المحاضرات" />
                <p>{convertToArabicNumbers(data?.data?.data?.lessons.toString())} درس</p>
              </div>
            </div>
          </div>
          <div className={`${style.right} mt-lg-0 mt-md-5`}>
            {!data?.data?.data?.is_enrolled && (
              <div className="w-100 d-flex gap-2 mb-2 mb-sm-0">
                <h2 className={`${style.offer}`}> {convertToArabicNumbers(data?.data?.data?.price.toString())} جنية</h2>
                <h2>{convertToArabicNumbers((data?.data?.data?.price - data?.data?.data?.discount_price).toString())} جنية</h2>
              </div>
            )}
            {!data?.data?.data?.is_enrolled && (
               <button className="w-100 btn" 
              onClick={() => { trackInitiateCheckout(); handleModalToggle(); }}>سجل في الدورة</button>
            )}
          </div>
        </div>

        {/* Enrollment Modal */}
        {showModal && (
  <div className={`${style.modalOverlay}`}>
    <div className={`${style.modalContent}`}>
      {!isPaymentConfirmed && step === 0 && (
        <>
          <h2 style={{ color: '#471c70' }}>اختار وسيلة الدفع:</h2>
          <div className={`${style.paymentOptions}`}>
            <button
              className={`${style.paymentButton} ${paymentMethod === 'cash' ? style.selected : ''}`}
              onClick={() => handlePaymentMethodChange('cash')}
            >
              كاش
            </button>
            <button
              className={`${style.paymentButton1} ${paymentMethod === 'installment' ? style.selected : ''}`}
              onClick={() => handlePaymentMethodChange('installment')}
            >
              تقسيط
            </button>
            <p className='text-danger'>*يجب إجراء عملية الدفع بجهاز الهاتف المحمول</p>
          </div>
          {paymentMethod && (
            <div className={`${style.selectedMethod}`}>
              <button
                className={`${style.nextButton}`}
                onClick={handleNext}
              >
                التالي
              </button>
            </div>
          )}
        </>
      )}
      {step === 1 && (
  <>
    <h2>معلومات الدورة:</h2>
    {paymentMethod === 'cash' ? null : slug === 'lmyny-gr-fyk-kryb' ? (
      <>
        <p>إجمالي سعر الدبلومة بالتقسيط: <strong>950 جنيه</strong></p>
        <p>يتم دفع: <strong>500 جنيه</strong> عند الاشتراك.</p>
        <p>الدفعة الثانية: <strong>500 جنيه</strong> تُدفع بعد أسبوعين من تاريخ الاشتراك.</p>
        <p className='text-warning'>يرجى العلم بأنه في حالة عدم إرسال المبلغ المستحق في الموعد المحدد، سيتم غلق الدبلومة.</p>
      </>
    ) : (
      <>
        <p>إجمالي سعر الدبلومة بالتقسيط: <strong>1,500 جنيه</strong></p>
        <p>يتم دفع: <strong>500 جنيه</strong> عند الاشتراك.</p>
        <p>الدفعة الثانية: <strong>500 جنيه</strong> تُدفع بعد أسبوعين من تاريخ الاشتراك.</p>
        <p>الدفعة الثالثة: <strong>500 جنيه</strong> تُدفع بعد أربع أسابيع من تاريخ الاشتراك.</p>
        <p className='text-warning'>يرجى العلم بأنه في حالة عدم إرسال المبلغ المستحق في الموعد المحدد، سيتم غلق الدبلومة.</p>
      </>
    )}
    <div className={`${style.paymentOptions}`}>
      <h3>اختر وسيلة الدفع:</h3>
      <label>
        <input
          type="radio"
          name="paymentMethodChoice"
          checked={paymentChoice === 'instapay'}
          onChange={() => setPaymentChoice('instapay')}
        />
        InstaPay
      </label>
      <label>
        <input
          type="radio"
          name="paymentMethodChoice"
          checked={paymentChoice === 'wallet'}
          onChange={() => setPaymentChoice('wallet')}
        />
        Wallet (01147099099)
      </label>
      
      {/* إضافة التنبيه هنا */}
      {paymentChoice === 'wallet' && paymentMethod === 'cash' && (
        <p className="text-warning">
          {slug === 'dblom-lsobr-gr-fyk-kryb' ? (
            <>سعر الدبلومة شامل رسوم التحويل: <strong>1465 جنيه</strong></>
          ) : (
            <>سعر الدبلومة شامل رسوم التحويل: <strong>960 جنيه</strong></>
          )}
        </p>
      )}

      {paymentChoice && paymentMethod  && (
        <p className='d-flex'>
          <a
              href="/Terms"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue', textDecoration: 'none' }}
              className='ms-2'
             
            >
              الشروط والأحكام
            </a>
          <a
              href="/Return"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'red', textDecoration: 'none' }}
             
            >
             سياسة الاسترجاع
            </a>

        </p>
      )}
      {paymentChoice && (
        <div>
          <label>
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            أؤكد أنني قرأت الشروط .
          </label>
        </div>
      )}
    </div>
    {paymentChoice && termsAccepted && (
      <div className={`${style.selectedMethod}`}>
        <button
          className={`${style.nextButton}`}
          onClick={() => {
            handleNext1();
            setStep(2);
          }}
        >
          التالي
        </button>
      </div>
    )}
  </>
)}

      {step === 2 && (
        <>
          <h2>أدخل معلومات الدفع:</h2>
          <div className={`${style.formGroup}`}>
            <input
              className="mt-4"
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="أدخل رقم الجوال (اختياري)"
            />
          </div>
          <div className={`${style.formGroup}`}>
            <input
              className="mt-4"
              type="text"
              value={TransferNumber}
              onChange={(e) => setTransferNumber(e.target.value)}
              placeholder="أدخل الرقم المُحوّل منه"
            />
          </div>
          <div className={`${style.formGroup}`}>
            <label>رفع صورة الدفع</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <div className={`${style.selectedMethod}`}>
            <button
              className={`${style.previousButton}`}
              onClick={() => handleprev1()}
            >
              السابق
            </button>
            <button
              className={`${style.nextButton}`}
              onClick={handleSendPaymentDetails}
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                'إرسال'
              )}
            </button>
          </div>
        </>
      )}
      <p className={`${style.closeButton}`} onClick={handleModalToggle}>
        X
      </p>
    </div>
  </div>
)}





        {/* Video and Playlist Section */}
        <div className={`${style.app}`}>
          <h2 className={style.videoTitle}>{videoTitle}</h2> 
          <div className='row'>
            <div className='col-lg-7 col-md-12'>
              <div 
                className={style.videoPlayer} 
                style={{ position: 'relative' }}
                onContextMenu={(e) => e.preventDefault()} 
              >
                {!currentVideo && (
                  <img 
                    src={data?.data?.data?.image} 
                    alt="Thumbnail"
                    style={{ width: '100%', height: 'auto', position: 'absolute', top: 0, left: 0 }}
                    className={`${style.thumb}`}
                  />
                )}
                 {currentVideo ? (
                  <>
                    <iframe
                      width="100%"  // عرض الفيديو بنسبة 100% من عرض الحاوية
                      height="100%"  // حجم الفيديو يعتمد على المحتوى
                      src={currentVideo}  // الرابط الخاص بالفيديو
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Video"
                    ></iframe>
                      
                     <div className="d-flex justify-content-between align-items-center mb-2">
                      <button className={`${style.purple} mb-3`} onClick={handleAttachmentsModalToggle}>
                        المرفقات
                      </button>
                    </div>

                    {showAttachmentsModal && (
              <div className={style.modalOverlay} onClick={handleAttachmentsModalToggle}>
                <div className={style.attachmentsModal} onClick={(e) => e.stopPropagation()}>
                  <h5 className="text-center">المرفقات</h5>
                  <button className="btn btn-danger mb-3" onClick={handleAttachmentsModalToggle}>إغلاق</button>
                  <div className={style.attachmentsContent}>
                    {attachments.length > 0 ? (
                      <div className="d-flex flex-wrap gap-3">
                        {attachments.map((attachment, index) => (
                          <div key={index} className={style.attachmentItem}>
                            <a href={attachment.url} target="_blank" className={style.attachmentLink}>
                              <div className={`${style.attachmentContent}`}>
                                <i className={`${style.attachmentIcon} fs-3 fa-solid fa-file-pdf text-dark`}></i>
                                <div className={`${style.messi} d-flex justify-content-center align-items-center`}>
                                  <p className={`${style.attachmentTitle} text-dark`}>{attachment.name}</p>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                        ) : (
                          <p className="text-center">لا توجد مرفقات</p>
                        )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='text-white'></div> 
                )}
              </div>
            </div>

            <div className='col-lg-5 col-md-12 mt-lg-0 mt-2 mt-sm-4'>
              <div className={`${style.playlist}`}>
                <h3>{data?.data?.data?.title}</h3>
                <p>منهج الدورة بالكامل</p>
                <div className={style.playlistItem}>
                  {data?.data?.data?.sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className={`${style.playlist} mb-3`}>
                      <div 
                        className={`${style.item} ${section.slug === sectionSlug ? style.active : ''} d-flex justify-content-between align-items-center`}
                        onClick={() => { 
                          handleSectionClick(section.slug); 
                          setExpandedSection(sectionIndex === expandedSection ? null : sectionIndex);
                        }}
                      > 
                        <h3>{section.title}</h3>
                        <FontAwesomeIcon 
                          icon={expandedSection === sectionIndex ? faChevronUp : faChevronDown} 
                          className={`${style.toggleIcon}`} 
                        />
                      </div>
                      {expandedSection === sectionIndex && (
                        <div className={`${style.playlistItem} h-auto`}>
                          {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                              <Spinner animation="border" variant="primary" />
                            </div>
                          ) : (
                            <>
                              {sectionDatum?.data?.length > 0 ? (
                                sectionDatum.data.map((lecture, index) => (
                                  <div
                                    key={lecture.slug}
                                    className={`${style.item} ${(index === activeIndex && section.slug === sectionSlug && lecture.slug === selectedLectureSlug) ? style.active : ''} d-flex justify-content-between align-items-center border-0`}
                                    onClick={() => handleVideoClick(index, section.slug, lecture.slug, lecture.title)}
                                  >
                                    <div className="d-flex gap-1 align-items-center">
                                      {!data?.data?.data?.is_enrolled && (
                                        <img src={Lock} alt="قفل" />
                                      )}
                                      <h4 className='me-2'>{lecture.title}</h4>
                                    </div>
                                    <h5>{lecture.hours}:{lecture.minutes}:{lecture.seconds}</h5>
                                  </div>
                                ))
                              ) : (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                                  <h5 className='text-white'>لا توجد محاضرات في هذا القسم حتى الأن </h5>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Course Information Section */}
        <div className={`${style.information} my-3`}>
          <div className='row'>
            <div className='col-lg-7 col-md-12 mb-4'>
              <div className={`${style.left}`}>
                <div className={`${style.header}`}>
                  <h2>معلومات عن الدورة</h2>
                </div>
                <div className={`${style.body} pt-1`}>
                  <div
                    className={`${style.description} text-white`}
                    dangerouslySetInnerHTML={{ __html: data?.data?.data?.description }}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className={`${style.right}`}>
                <h2>المحاضر</h2>
                <h3>{data?.data?.data?.instructor?.name}</h3>
                <p>{data?.data?.data?.instructor?.description}</p>
                <p className='h3'>
                  <strong>للتواصل مع المحاضر:</strong>{' '}
                  <a 
                    href="https://wa.me/+201147099099" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ color: 'white', textDecoration: 'none' }}
                    className='h5 fw-bold'
                  >
                    01147099099
                  </a>
                </p>
                <h2 className="pt-2">البرامج في الباقة</h2>
                {data?.data?.data?.programs.map((item) => (
                  <div key={item.slug} className={`${style.programs} d-flex align-items-center gap-1`}>
                    <img src={item.icon} alt={item.name} />
                    <div>
                      <p>{item.name}</p>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
      <Toaster /> {/* Ensure Toaster is included for toast notifications */}
    </>
  );  
};

export default Courses;
